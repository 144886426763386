<header>
    <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'add_circle' }"></app-icon>
    <h2 mat-dialog-title>Confirm Booking {{ booking && booking.id ? 'Changes' : '' }}</h2>
</header>
<mat-dialog-content class="body" *ngIf="booking && !loading; else load_state">
     <div class="row" name="host">
        <app-icon
            [icon]="{ type: 'icon', class: 'material-icons', content: 'perm_identity' }"
        ></app-icon>
        <label>{{ booking.organizer?.name }}</label>
    </div>
    <div class="row">
        <app-icon
            [icon]="{ type: 'icon', class: 'material-icons', content: 'schedule' }"
        ></app-icon>
        <label>{{ when }}</label>
    </div>
    <div class="row">
        <app-icon
            [icon]="{ type: 'icon', class: 'material-icons', content: 'title' }"
        ></app-icon>
        <label>{{ title || 'No title' }}</label>
    </div>

    <div class="row" *ngIf="!!booking.resources[0]">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'place' }"></app-icon>
        <label>{{ booking.resources[0].name }}</label>
        <a (tapped)="showSpace(booking.resources[0])">View</a>
    </div>

    <div class="row" name="attendees">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'people_outline' }"></app-icon>
        <label>
            {{ booking.attendees.length || '0' }} Attendee{{(booking.attendees.length !== 1 ? 's' : '')}}
        </label>
        <a (tapped)="showAttendees()">View</a>
    </div>

    <div class="row" *ngIf="!!booking.body">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'subject' }"></app-icon>
        <label>
            <span [innerHTML]="booking.body"></span>
        </label>
    </div>
    <div class="row" *ngIf="booking && booking.has_catering" name="booking.catering">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'room_service' }"></app-icon>
        <label>
            <ng-container *ngIf="catering_items_total < 1; else cateringItems">
                No catering added
            </ng-container>
            <ng-template #cateringItems>
                <ng-container *ngIf="catering_items.length === 1; else multipleCatering">
                    {{catering_items[0].item}} X {{catering_items[0].qty}}
                </ng-container>
            </ng-template>
            <ng-template #multipleCatering>
                {{catering_items_total}} catering items
            </ng-template>
        </label>
        <a *ngIf="catering_items_total > 0 && catering_items.length > 1" (tapped)="showCatering()">View</a>
    </div>

</mat-dialog-content>
<mat-dialog-actions *ngIf="!loading">
    <button mat-dialog-close>Cancel</button>
    <button class="black" (tapped)="saveChanges()">Save</button>
</mat-dialog-actions>
<ng-template #load_state>
    <mat-dialog-content class="body">
        <div class="info-block">
            <div class="icon">
                <mat-spinner diameter="32"></mat-spinner>
            </div>
            <div class="text">
                {{
                    checking_available
                        ? 'Checking space availability...'
                        : booking.id
                        ? 'Updating booking...'
                        : 'Creating booking...'
                }}
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
