
<div class="shell">
    <div class="view" *ngIf="!show_login">
        <router-outlet></router-outlet>
    </div>
    <div class="login-module" *ngIf="show_login">
        <app-login></app-login>
    </div>
    <div class="loading-block" *ngIf="is_loading">
        <div class="info-block">
            <div class="header" [style.background-color]="logo?.background">
                <div class="logo">
                    <i *ngIf="logo?.type === 'icon'" [class]="logo.class">{{ logo.value }}</i>
                    <img *ngIf="logo?.type === 'img'" [src]="logo.src | safe:'resource'" />
                </div>
            </div>
            <div class="item" *ngFor="let item of loading_state_list">
                <div class="message">{{ item.message }}</div>
                <div class="icon">
                    <ng-container [ngSwitch]="item.state">
                        <ng-container *ngSwitchCase="'complete'">
                            <i class="material-icons success">done</i>
                        </ng-container>
                        <ng-container *ngSwitchCase="'failed'">
                            <i class="material-icons failure">close</i>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <mat-spinner diameter="16"></mat-spinner>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>