import { HashMap } from 'src/app/shared/utilities/types.utilities';
import { ServiceManager } from '../service-manager.class';
import { Organisation } from '../organisation/organisation.class';
import { BuildingLevel } from '../organisation/level.class';
import { RoomConfiguration } from '../shared/room-configuration.interface';

export class Space {
    /** ID of the space resource */
    public readonly id: string;
    /** Display name of the space resource */
    public readonly name: string;
    /** Email address of the resource associated with space */
    public readonly email: string;
    /** Capacity of the space resource */
    public readonly capacity: string;
    /** List of features available at the space resource  */
    public readonly feature_list: string[];
    /** URL to a supporting site for space */
    public readonly support_url: string;
    /** ID of the space on the associated map */
    public readonly map_id: string;
    /** Whether resource is bookable */
    public readonly bookable: boolean;
    /** List of zones the spaces is associated with */
    public readonly zones: string[];
    /** List of available room configurations for this room */
    public readonly configurations: readonly RoomConfiguration[];

    /** Level associated with the space */
    public get level(): BuildingLevel {
        const service = ServiceManager.serviceFor(Organisation);
        return service ? service.levelWithID(this.zones) : new BuildingLevel({});
    }

    private _service(): any {
        ServiceManager.serviceFor(Space);
    }

    constructor(data: HashMap = {}) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.email = data.email || '';
        this.capacity = data.capacity || -1;
        this.feature_list = (data.features || '').split(' ');
        this.bookable = !!data.bookable;
        this.zones = data.zones || [];
        this.support_url = data.support_url || '';
        this.map_id = data.map_id || '';
        this.configurations = data.configurations || [];
    }

    /** TO DO: To grab map_id number for TEST */
    public get short_map_id(): string {
        return this.map_id.split('-')[1];
    }
}
