<div class="time-field" role="button" form-field tabindex="0" (keydown.enter)="showSelect()">
    <div class="display">
        <input matInput type="time" [ngModel]="time" (ngModelChange)="setValue($event)" />
    </div>
    <div class="icon" (tapped)="showSelect()">
        <app-icon
            [icon]="{
                type: 'icon',
                class: 'material-icons',
                content: show_select ? 'arrow_drop_up' : 'arrow_drop_down'
            }"
        ></app-icon>
    </div>
</div>
<mat-form-field appearance="outline" *ngIf="show_select">
    <mat-select #select [value]="time" (valueChange)="setValue($event)">
        <mat-option *ngFor="let option of time_options" [value]="option.value">
            {{ option.display }}
        </mat-option>
    </mat-select>
</mat-form-field>
