<div
    class="overlay-menu"
    [class.left]="settings?.position === 'left'"
    [class.middle]="settings?.position === 'middle'"
    [class.show]="show"
    (tapped)="delayedClose()"
>
    <div class="menu" (tapped)="cancelClose()">
        <div class="header">
            <div class="location"></div>
            <button mat-icon-button (tapped)="close()">
                <app-icon
                    [icon]="{ type: 'icon', class: 'material-icons', content: 'close' }"
                ></app-icon>
            </button>
        </div>
        <div class="body">
            <ul>
                <ng-container *ngIf="!!menu_items">
                    <ng-container *ngFor="let item of menu_items">
                        <ng-container *ngIf="item.route; else external_link">
                            <a
                                [routerLink]="[item.route]"
                                routerLinkActive="active"
                                [queryParams]="item.query_params"
                            >
                                <li>
                                    <div class="text">{{ item.name }}</div>
                                    <app-icon
                                        [icon]="{
                                            type: 'icon',
                                            class: 'material-icons',
                                            content: 'keyboard_arrow_right'
                                        }"
                                    ></app-icon>
                                </li>
                            </a>
                            <ng-container *ngIf="item.children && item.children.length">
                                <a
                                    *ngFor="let sub_item of item.children"
                                    class="sub-link"
                                    [routerLink]="[sub_item.route]"
                                    routerLinkActive="active"
                                    [queryParams]="sub_item.query_params"
                                >
                                    <li>
                                        <div class="text">{{ sub_item.name }}</div>
                                        <app-icon
                                            [icon]="{
                                                type: 'icon',
                                                class: 'material-icons',
                                                content: 'keyboard_arrow_right'
                                            }"
                                        ></app-icon>
                                    </li>
                                </a>
                            </ng-container>
                        </ng-container>
                        <ng-template #external_link>
                            <a [href]="item.link | safe: 'url'">
                                <li>
                                    <div class="text">{{ item.name }}</div>
                                    <app-icon
                                        [icon]="{
                                            type: 'icon',
                                            class: 'material-icons',
                                            content: 'exit_to_app'
                                        }"
                                    ></app-icon>
                                </li>
                            </a>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
        <div class="footer">
            Powered by <img src="assets/img/cisco-logo.svg" alt="cisco-logo"> and <img src="assets/img/placeos-logo.svg" alt="placeos-logo">
        </div>
    </div>
</div>
