import { Type } from '@angular/core';

export interface ServiceProvider<T = any> {
    provideFor: Type<any>;
    useValue: T;
}

export class ServiceManager {
    /** Map of available services for child classes */
    private static _service_list: ServiceProvider<any>[] = [];
    /** Set the services used to handle data model requests */
    public static setService<T>(type: Type<any>, service: T): void {
        ServiceManager._service_list.push({ provideFor: type, useValue: service });
    }
    /** Get the services used to handle data model requests */
    public static serviceFor<T = any>(type: Type<any>): T {
        const provider = ServiceManager._service_list.find(
            (provider) => provider.provideFor === type
        ) || { useValue: null };
        return provider.useValue;
    }

    constructor() {
        throw new Error('ServiceMananger is static class');
    }
}
