<div class="topbar-header" [class.reverse]="reverse">
    <div class="block">
        <a [routerLink]="['/home']" *ngIf="logo">
            <div class="logo">
                <i *ngIf="logo.type === 'icon'" [class]="logo.class">{{ logo.content }}</i>
                <img *ngIf="logo.type === 'img'" [src]="logo.src | safe: 'resource'" />
            </div>
        </a>
    </div>
    <div class="block middle">
        <a-searchbar *ngIf="search" placeholder="Search for people or spaces..."></a-searchbar>
    </div>
    <div class="block">
        <button mat-icon-button (tapped)="showMenu()">
            <app-icon
                [icon]="{ type: 'icon', class: 'material-icons', content: 'menu' }"
            ></app-icon>
        </button>
    </div>
</div>
