import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BaseDirective } from 'src/app/shared/base.directive';
import { ApplicationService } from 'src/app/services/app.service';

import * as dayjs from 'dayjs';
import { humaniseDuration } from 'src/app/shared/utilities/general.utilities';
import { Space } from 'src/app/services/data/spaces/space.class';
import { User } from 'src/app/services/data/users/user.class';
import { ViewCateringModalComponent } from 'src/app/overlays/view-catering-modal/view-catering-modal.component';
import { ViewAttendeesModalComponent } from 'src/app/overlays/view-attendees-modal/view-attendees-modal.component';
import { ViewRoomModalComponent } from 'src/app/overlays/view-room-modal/view-room-modal.component';
import { StaffService } from 'src/app/services/data/users/staff.service';
import { CalendarEvent } from 'src/app/services/data/events/event.class';
import { CateringItem } from 'src/app/services/data/catering/catering-item.class';

@Component({
    selector: 'schedule-event-view',
    templateUrl: './event-view.component.html',
    styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent extends BaseDirective {
    @Input() public event: CalendarEvent;
    @Input() public form: FormGroup;

    constructor(private _service: ApplicationService, private _users: StaffService, private _dialog: MatDialog) {
        super();
    }

    /** Host of the event */
    public get organiser(): User {
        return this.event.organizer || this._users.current;
    }

    /** Display string of when the event will occur */
    public get when(): string {
        if (!this.event) {
            return 'Unable to determine.';
        }
        const date = dayjs(this.event.date);
        const end = date.add(this.event.duration || 60, 'm');
        if (this.event.all_day || this.event.duration > 23 * 60) {
            return `${date.format('DD MMM YYYY')} - All Day`;
        } else {
            if (date.isSame(end, 'd')) {
                return `${date.format('DD MMM YYYY, h:mm A')} - ${end.format('h:mm A')}`;
            } else {
                return `${date.format('DD MMM YYYY, h:mm A')} - ${end.format(
                    'DD MMM YYYY, h:mm A'
                )}`;
            }
        }
    }

    /** Display string for the event's duration */
    public get duration(): string {
        return humaniseDuration(this.event.duration || 60);
    }

    /** Display catering items */
    public get catering_items(): CateringItem[] {
        const order =  this.event.catering[0];
        return order ? order.items : [];
    }

    public get catering_note(): string {
        // return this.event.catering_notes;
        // TODO
        return '';
    }

    /** List of spaces associated with the event */
    public get spaces(): Space[] {
        return this.event.resources;
    }

    /** Display string for the list of active spaces */
    public get spaces_dropdown(): string {
        return (
            (this.form.controls.space_list.value || []).map(space => space.name).join(', ') ||
            'No Spaces selected'
        );
    }

    /** List of attendees associated with the event not including the host */
    public get attendees(): User[] {
        return this.event.attendees.filter(i => i.email !== this.organiser.email) || [];
    }

    /** Display string for meeting link */
    public get link(): string {
        return this.event.meeting_link;
    }

    /** Display string for notes */
    public get body(): string {
        return this.event.body;
    }

    public showCatering() {
        const dialog_ref = this._dialog.open(ViewCateringModalComponent, {
            width: '32em',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { catering: this.catering_items, catering_note: this.catering_note }
        });
    }

    public showAttendees() {
        const dialog_ref = this._dialog.open(ViewAttendeesModalComponent, {
            width: '32em',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { attendees: this.attendees }
        });
    }

    public showSpace(space: Space) {
        const dialog_ref = this._dialog.open(ViewRoomModalComponent, {
            width: '40vw',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { space }
        });
    }
}
