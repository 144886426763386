<div class="user-list-field" form-field>
    <mat-form-field appearance="outline">
        <input
            matInput
            name="user-search"
            [(ngModel)]="search_str"
            (ngModelChange)="search$.next($event)"
            placeholder="Search for user..."
            [matAutocomplete]="auto"
        />
        <div class="prefix" matPrefix>
            <app-icon
                [icon]="{ type: 'icon', class: 'material-icons', content: 'search' }"
            ></app-icon>
        </div>
        <div class="suffix" matSuffix *ngIf="loading">
            <mat-spinner diameter="16"></mat-spinner>
        </div>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addUser($event.option.value)">
        <mat-option *ngFor="let option of user_list" [value]="option">
            <div class="name">{{ option.name }}</div>
            <div class="email">{{ option.email }}</div>
        </mat-option>
    </mat-autocomplete>

    <div class="user-list">
        <mat-chip-list
            aria-label="User List"
            *ngIf="active_list && active_list.length; else empty_state"
        >
            <mat-chip
                *ngFor="let user of active_list"
                [id]="user.email"
                [color]="user.external ? 'accent' : 'primary'"
                [removable]="true"
                (removed)="removeUser(user)"
            >
                {{ user.name }}
                <app-icon
                    matChipRemove
                    [icon]="{ type: 'icon', class: 'material-icons', content: 'close' }"
                ></app-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="actions">
        <button type="button" name="new-contact" matRipple (tapped)="new_user.emit()">
            <app-icon
                    [icon]="{ type: 'icon', class: 'material-icons', content: 'add_circle' }"
                ></app-icon>New Contact
        </button>
        <button type="button" name="download-template" matRipple (tapped)="downloadCSVTemplate()">
            <app-icon
                    [icon]="{ type: 'icon', class: 'material-icons', content: 'get_app' }"
                ></app-icon>CSV
        </button>
        <button type="button" name="upload-csv" matRipple>
            <app-icon
                    [icon]="{ type: 'icon', class: 'material-icons', content: 'note_add' }"
                ></app-icon>Bulk (CSV)
            <input type="file" (change)="addUsersFromFile($event)" />
        </button>
    </div>
</div>
<ng-template #empty_state>
    <div>No attendees</div>
</ng-template>
