import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShellWrapperComponent } from './shell/shell.component';
import { HomeComponent } from './shell/home/home.component';

const routes: Routes = [
    {
        path: '',
        component: ShellWrapperComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'book', loadChildren: () => import('./shell/bookings/bookings.module').then(m => m.BookingsModule) },
            { path: 'explore', loadChildren: () => import('./shell/explore/explore.module').then(m => m.ExploreModule) },
            { path: 'control', loadChildren: () => import('./shell/control/control.module').then(m => m.ControlModule) },
            { path: 'directory', loadChildren: () => import('./shell/directory/directory.module').then(m => m.DirectoryModule) },
            { path: 'schedule', loadChildren: () => import('./shell/schedule/schedule.module').then(m => m.ScheduleModule) },
            { path: 'help', loadChildren: () => import('./shell/help/help.module').then(m => m.HelpModule) },
            { path: '**', redirectTo: 'home' }
        ]
    },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
