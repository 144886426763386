import { Injectable } from '@angular/core';

import { ComposerService } from '@placeos/composer';

import { Organisation } from './organisation.class';
import { Building } from './building.class';
import { ApplicationLoadingState } from '../../../shared/utilities/types.utilities';
import { BuildingLevel } from './level.class';
import { BaseClass } from 'src/app/shared/base.class';
import { BehaviorSubject } from 'rxjs';
import { ServiceManager } from '../service-manager.class';
import { ApplicationService } from '../../app.service';
import { first } from 'rxjs/operators';
import { RoomConfiguration } from '../shared/room-configuration.interface';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends BaseClass {

    private readonly buildings_subject = new BehaviorSubject<Building[]>([]);
    private readonly active_building_subject = new BehaviorSubject<Building>(null);
    private readonly levels_subject = new BehaviorSubject<BuildingLevel[]>([]);

    /** Observable for the list of buildings */
    public readonly building_list = this.buildings_subject.asObservable();
    /** Observable for the list of levels */
    public readonly level_list = this.levels_subject.asObservable();
    /** Observable for the currently active building */
    public readonly active_building = this.active_building_subject.asObservable();
    /** Organisation data for the application */
    private _organisation: Organisation;

    /** Organisation data for the application */
    public get organisation(): Organisation {
        return this._organisation;
    }

    /** List of available buildings */
    public get buildings(): Building[] {
        return this.buildings_subject.getValue();
    }

    /** Currently active building */
    public get building(): Building {
        return this.active_building_subject.getValue();
    }
    public set building(bld: Building) {
        this.active_building_subject.next(bld);
    }

    /** List of available levels */
    public get levels(): BuildingLevel[] {
        return this.levels_subject.getValue();
    }

    constructor(protected _composer: ComposerService, private _app: ApplicationService) {
        super();
        ServiceManager.setService(Organisation, this);
        this._app.initialised.pipe(first(_ => _)).subscribe(() => {
            this.init();
        });
    }

    /**
     * Get level with a matching ID
     * @param id_list List of IDs to find a match
     */
    public levelWithID(id_list: string[]): BuildingLevel {
        return this.levels.find(lvl => id_list.includes(lvl.id));
    }

    /**
     * Get list of levels for the given building
     * @param bld Building to list levels for
     */
    public levelsForBuilding(bld: Building): BuildingLevel[] {
        return this.levels.filter(lvl => lvl.parent_id === bld.id);
    }

    private async init() {
        this._initialised.next(false);
        await this.load().catch((err) => {
            this._app.notifyError('Error loading organisation data. Retrying...');
            this.timeout('init', () => this.init());
            throw err;
        });
        this._initialised.next(true);
    }

    /**
     * Initialise service data
     */
    private async load(): Promise<void> {
        const loading: ApplicationLoadingState = this._app.get('loading') || {};
        if (!loading.organisation) {
            loading.organisation = { message: 'Loading organisation data', state: 'loading' };
            this._app.set('loading', loading);
        }
        await this.loadOrganisation();
        loading.organisation = { message: 'Loading organisation data', state: 'complete' };
        if (!loading.buildings) {
            loading.buildings = { message: 'Loading building data', state: 'loading' };
        }
        this._app.set('loading', loading);
        await this.loadBuildings();
        loading.buildings = { message: 'Loading building data', state: 'complete' };
        if (!loading.levels) {
            loading.levels = { message: 'Loading building floor data', state: 'loading' };
        }
        this._app.set('loading', loading);
        await this.loadLevels();
        loading.levels = { message: 'Loading building floor data', state: 'complete' };
        this._app.set('loading', loading);
        if (localStorage) {
            const id = localStorage.getItem(`${this._app.name}.building`);
            if (id && this.buildings.find(bld => bld.id === id)) {
                this.active_building_subject.next(this.buildings.find(bld => bld.id === id));
            }
        }
    }

    /**
     * Load organisation data for application
     */
    private async loadOrganisation(): Promise<void> {
        const org_list = await this._composer.zones.query({ tags: 'org' } as any);
        if (org_list.length) {
            this._organisation = new Organisation(org_list[0]);
        }
    }

    /**
     * Load buildings data for the organisation
     */
    private async loadBuildings(): Promise<void> {
        const building_list = await this._composer.zones.query({ tags: 'building', limit: 500 } as any);
        const buildings = building_list.map(bld => new Building(bld));
        this.buildings_subject.next(buildings);
        if (!this.building && buildings && buildings.length > 0) {
            this.building = buildings[0];
        }
    }

    /**
     * Load levels data for the buildings
     */
    private async loadLevels(): Promise<void> {
        const level_list = await this._composer.zones.query({ tags: 'level', limit: 2500 } as any);
        const levels = level_list.map(lvl => new BuildingLevel(lvl));
        this.levels_subject.next(levels);
    }

    public get available_room_configs(): RoomConfiguration[] {
        return this.buildings.map(m => m.room_configurations)
            .reduce((prev, curr) => prev.concat(curr), [])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
}
