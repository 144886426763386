import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, map, catchError } from 'rxjs/operators';

import { BaseDirective } from 'src/app/shared/base.directive';
import { User } from 'src/app/services/data/users/user.class';
import { ApplicationService } from 'src/app/services/app.service';
import { filterList, matchToHighlight } from 'src/app/shared/utilities/general.utilities';
import { StaffService } from 'src/app/services/data/users/staff.service';

@Component({
    selector: 'a-user-search-field',
    templateUrl: './user-search-field.component.html',
    styleUrls: ['./user-search-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSearchFieldComponent),
            multi: true
        }
    ]
})
export class UserSearchFieldComponent extends BaseDirective implements OnInit, ControlValueAccessor {
    /** Limit available options to these */
    @Input() public options: User[];
    /** Currently selected user */
    public active_user: User;
    /** User list to display */
    public user_list: User[];
    /** Whether user list is loading */
    public loading: boolean;
    /** Current display value of the search input field  */
    public search_str: string;
    /** List of users from an API search */
    public search_results$: Observable<User[]>;
    /** Subject holding the value of the search */
    public search$ = new Subject<string>();

    /** Form control on change handler */
    private _onChange: (_: User) => void;
    /** Form control on touch handler */
    private _onTouch: (_: User) => void;

    constructor(private _users: StaffService) {
        super();
    }

    public ngOnInit(): void {

        // Listen for input changes
        this.search_results$ = this.search$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(query => {
                this.loading = true;
                console.log('Query:', query, (this.options || []).map(i => i.name));
                return this.options && this.options.length > 0
                    ? Promise.resolve(this.options)
                    : query.length >= 3
                        ? (this._users.list(query) as Promise<User[]>)
                        : Promise.resolve([]);
            }),
            catchError((err) => of([])),
            map((list: User[]) => {
                this.loading = false;
                return filterList(this.search_str, list, ['name', 'email']);
            })
        );
        // Process API results
        this.subscription('search_results', this.search_results$.subscribe(list => {
            this.user_list = list;
            this.user_list.forEach((i: any) => {
                i.match_name = matchToHighlight(i.match_name);
                i.match_email = matchToHighlight(i.match_email);
                return i;
            });
        }));
    }

    /**
     * Reset the search string back to the name of the active user
     */
    public resetSearchString() {
        if (this.active_user) {
            this.search_str = this.active_user.name;
        }
    }

    /**
     * Update the form field value
     * @param new_value New value to set on the form field
     */
    public setValue(new_value: User): void {
        this.active_user = new_value;
        if (this._onChange) {
            this._onChange(new_value);
        }
        this.resetSearchString();
    }

    /**
     * Update local value when form control value is changed
     * @param value The new value for the component
     */
    public writeValue(value: User) {
        this.active_user = value;
        this.resetSearchString();
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * @param fn The callback function to register
     */
    public registerOnChange(fn: (_: User) => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * @param fn The callback function to register
     */
    public registerOnTouched(fn: (_: User) => void): void {
        this._onTouch = fn;
    }
}
