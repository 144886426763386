import { Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { OptionBlock } from 'src/app/shared/utilities/types.utilities';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BaseDirective } from 'src/app/shared/base.directive';
import { humaniseDuration } from 'src/app/shared/utilities/general.utilities';

@Component({
    selector: 'a-duration-field',
    templateUrl: './duration-field.component.html',
    styleUrls: ['./duration-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DurationFieldComponent),
            multi: true
        }
    ]
})
export class DurationFieldComponent extends BaseDirective implements OnInit, OnChanges, ControlValueAccessor {
    /** Maximum duration option available */
    @Input() public max: number = 240;
    /** Minimum duration option available */
    @Input() public min: number = 15;
    /** Step value between options */
    @Input() public step: number = 15;
    /** Is booking modal */
    @Input() public is_modal: boolean = false;

    public duration: string = '60';
    /** List of available duration options */
    public duration_options: OptionBlock[];

    /** Form control on change handler */
    private _onChange: (_: number) => void;
    /** Form control on touch handler */
    private _onTouch: (_: number) => void;

    public ngOnInit(): void {
        this.duration_options = this.generateDurationOptions(this.max, this.min, this.step);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.max || changes.min || changes.step) {
            this.duration_options = this.generateDurationOptions(this.max, this.min, this.step);
        }
    }

    /**
     * Update the form field value
     * @param new_value New value to set on the form field
     */
    public setValue(new_value: string): void {
        this.duration = new_value;
        if (this._onChange) {
            this._onChange(+new_value);
        }
    }

    /**
     * Update local value when form control value is changed
     * @param value The new value for the component
     */
    public writeValue(value: number) {
        this.duration = `${value}`;
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * @param fn The callback function to register
     */
    public registerOnChange(fn: (_: number) => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * @param fn The callback function to register
     */
    public registerOnTouched(fn: (_: number) => void): void {
        this._onTouch = fn;
    }

    private generateDurationOptions(max: number, min: number = 15, step: number = 15): OptionBlock[] {
        const blocks: OptionBlock[] = [];
        let time = min;
        while (time <= max) {
            blocks.push({
                display: `${humaniseDuration(time)}`,
                value: `${time}`
            });
            time += step;
        }
        return blocks;
    }
}
