import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, map, catchError } from 'rxjs/operators';

import { BaseDirective } from 'src/app/shared/base.directive';
import { User } from 'src/app/services/data/users/user.class';
import { ApplicationService } from 'src/app/services/app.service';
import { filterList, matchToHighlight, downloadFile, csvToJson } from 'src/app/shared/utilities/general.utilities';
import { StaffService } from 'src/app/services/data/users/staff.service';

@Component({
    selector: 'a-user-list-field',
    templateUrl: './user-list-field.component.html',
    styleUrls: ['./user-list-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserListFieldComponent),
            multi: true
        }
    ]
})
export class UserListFieldComponent extends BaseDirective implements OnInit, ControlValueAccessor {
    /** Emitter for action to make a new user */
    @Output('newUser') public new_user = new EventEmitter<void>();

    /** User list to display */
    public user_list: User[];
    /** List of active selected users on the list */
    public active_list: User[];
    /** Whether user list is loading */
    public loading: boolean;
    /** String  */
    public search_str: string;
    /** List of users from an API search */
    public  search_results$: Observable<User[]>;
    /** Subject holding the value of the search */
    public search$ = new Subject<string>();

    /** Form control on change handler */
    private _onChange: (_: User[]) => void;
    /** Form control on touch handler */
    private _onTouch: (_: User[]) => void;

    constructor(private _service: ApplicationService, private _users: StaffService) {
        super();
    }

    ngOnInit() {

        // Listen for input changes
        this.search_results$ = this.search$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(query => {
                this.loading = true;
                return  query.length >= 3
                    ? this._users.list(query)
                    : Promise.resolve([]);
            }),
            catchError((_) => of([])),
            map((list: User[]) => {
                this.loading = false;
                return filterList(this.search_str, list, ['name', 'email']);
            })
        );
        // Process API results
        this.subscription('search_results', this.search_results$.subscribe(list => {
            this.user_list = list;
            this.user_list.forEach((i: any) => {
                i.match_name = matchToHighlight(i.match_name);
                i.match_email = matchToHighlight(i.match_email);
                return i;
            });
        }));
    }

    /**
     * Add user to the user list
     * @param user
     */
    public addUser(user: User) {
        if (!this.active_list) {
            this.active_list = [];
        }
        const index = this.active_list.findIndex(a_user => a_user.email === user.email);
        if (index < 0) {
            this.active_list = [...this.active_list, user];
        }
        this.setValue(this.active_list);
        this.search_str = '';
    }

    /**
     * Remove user from the user list
     * @param user
     */
    public removeUser(user: User) {
        this.active_list = this.active_list.filter(a_user => a_user.email !== user.email);
        this.setValue(this.active_list);
    }

    /**
     * Load CSV file and populate the user list with the contents
     * @param event File input field event
     */
    public addUsersFromFile(event) {
        if (event.target) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');

                reader.onload = (evt) => {
                    this.processCsvData((evt.srcElement as any).result);
                    event.target.value = '';
                };
                reader.onerror = (evt) => this._service.notifyError('Error reading file.');
            }
        }
    }

    /**
     * Process raw CSV data and save user data to attendee list
     * @param data CSV data
     */
    private processCsvData(data: string) {
        const list = csvToJson(data) || [];
        const id = this._users.current.staff_id;
        list.forEach(el => {
            el.name = el.name || `${el.first_name} ${el.last_name}`;
            const display = (el.name || `${Math.floor(Math.random() * 9999_9999)}`)
                .split(' ').join('_').toLowerCase();
            if (!el.email) { el.email = `${display}+${id}@guest.mckinsey.com` }
            el.type = 'external';
            this.addUser(new User(el));
        });
    }

    /** Download template CSV file */
    public downloadCSVTemplate() {
        const template = `Organisation Name,First Name,Last Name,Email,Phone\nFake Org,John,Smith,john.smith@example.com,01234567898`;
        downloadFile('template.csv', template);
    }

    /**
     * Update the form field value
     * @param new_value New value to set on the form field
     */
    public setValue(new_value: User[]): void {
        this.active_list = new_value;
        if (this._onChange) {
            this._onChange(new_value);
        }
    }

    /**
     * Update local value when form control value is changed
     * @param value The new value for the component
     */
    public writeValue(value: User[]) {
        this.active_list = value;
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * @param fn The callback function to register
     */
    public registerOnChange(fn: (_: User[]) => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * @param fn The callback function to register
     */
    public registerOnTouched(fn: (_: User[]) => void): void {
        this._onTouch = fn;
    }
}
