/** Domain of the organisation */
export const DOMAIN = 'ashrae.com';
/** Endpoint where the staff API is located */
export const API = '/api/staff/v1';

export const FEATURES = [
    { extra_id: 'VidConf', extra_name: 'Video Conference' },
    { extra_id: 'AV', extra_name: 'AV' },
    { extra_id: 'presentation', extra_name: 'Wireless Presentation' },
    { extra_id: 'phone', extra_name: 'Conference Phone' }
];

export const ROOM_CONFIGS = [
    { id: 'boardroom', name: 'Boardroom', description: 'A boardroom setting' },
    { id: 'cocktail', name: 'Cocktail', description: 'A cocktail setting' },
    { id: 'theatre', name: 'Theatre', description: 'A theatre setting' },
    { id: 'other', name: 'Other', description: 'Another setting' }
];

export const ROOMS_PER_LEVEL = {
    'zone_lvl-00': [
        {
            id: '00.09',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.10',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.11',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.21',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.22',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.23',
            name: 'Classroom',
            bookable: false
        },
        {
            id: '00.25',
            name: 'Classroom',
            bookable: false
        }
    ],
    'zone_lvl-01': [
        {
            id: '01.07',
            name: 'Meeting Room',
            bookable: true
        },
        {
            id: '01.14',
            name: 'Collaboration Room',
            bookable: true
        },
        {
            id: '01.15',
            name: 'Collaboration Room',
            bookable: true
        },
        {
            id: '01.33',
            name: 'Induction Room',
            bookable: true
        },
        {
            id: '01.34',
            name: 'Induction Room',
            bookable: true
        }
    ],
    'zone_lvl-02': [
        // {
        //     id: '02.03',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.06',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.07',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.08',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.09',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        {
            id: '02.14',
            name: 'Forensic Lab',
            bookable: true
        },
        {
            id: '02.16',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '02.17',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '02.18',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '02.20',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '02.21',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        // {
        //     id: '02.22',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.24',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.25',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '02.26',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '02.27',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '02.31',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '02.32',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.33',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.34',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.37',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '02.38',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // }
    ],
    'zone_lvl-03': [
        // {
        //     id: '03.03',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.06',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.07',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.08',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.09',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        {
            id: '03.14',
            name: 'Forensic Lab',
            bookable: true
        },
        {
            id: '03.16',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '03.17',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '03.18',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '03.20',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '03.21',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '03.22',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        // {
        //     id: '03.24',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.25',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '03.26',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '03.27',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '03.31',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '03.32',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.33',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.34',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.37',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '03.38',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // }
    ],
    'zone_lvl-04': [
        // {
        //     id: '04.03',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.06',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.07',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.08',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.09',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        {
            id: '04.14',
            name: 'Forensic Lab',
            bookable: true
        },
        {
            id: '04.16',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '04.17',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '04.18',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '04.20',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '04.21',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        {
            id: '04.22',
            name: 'Multi-Purpose Room',
            bookable: true
        },
        // {
        //     id: '04.24',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.25',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '04.26',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '04.27',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '04.31',
        //     name: 'Multi-Purpose Area',
        //     bookable: true
        // },
        // {
        //     id: '04.32',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.33',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.34',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.37',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // },
        // {
        //     id: '04.38',
        //     name: 'Multi-Purpose Room',
        //     bookable: true
        // }
    ],
    'zone_lvl-05': [
        {
            id: '05.06',
            name: 'Leadership',
            bookable: false
        },
        {
            id: '05.07',
            name: 'Leadership',
            bookable: false
        },
        {
            id: '05.08',
            name: 'Leadership',
            bookable: false
        },
        {
            id: '05.09',
            name: 'Leadership',
            bookable: false
        }
    ]
};

const mockLevels = (buildingName: string): any[] => {
    const map_url = `assets/maps/${buildingName}`;


    return [
        {
            level_id: 'zone_lvl-00',
            level_name: 'Ground',
            number: 0,
            map_url: `${map_url}/level_G.svg`,
            floor_type: 'reception',
            desks: 6,
        },
        {
            level_id: 'zone_lvl-01',
            level_name: 'Level 1',
            number: 1,
            map_url: `${map_url}/level_01.svg`,
            floor_type: 'staff',
            settings: {
                map_features: [
                    { id: 'Device_Bench', name: 'Device Bench' },
                    { id: 'people', name: 'People' },
                ],
                zones: ['zone-01.A', 'zone-01.B', 'zone-01.C'],
            },
            desks: 6,
        },
        {
            level_id: 'zone_lvl-02',
            level_name: 'Level 2',
            number: 2,
            map_url: `${map_url}/level_02.svg`,
            floor_type: 'client',
            book_type: 'Request',
            settings: {
                zones: ['zone-02.A', 'zone-02.B', 'zone-02.C'],
            },
            desks: 6,
        },
        {
            level_id: 'zone_lvl-03',
            level_name: 'Level 3',
            number: 3,
            map_url: `${map_url}/level_03.svg`,
            floor_type: 'staff',
            settings: {
                zones: ['zone-03.A', 'zone-03.B', 'zone-03.C'],
            },
            desks: 6,
        },
        {
            level_id: 'zone_lvl-04',
            level_name: 'Level 4',
            number: 4,
            map_url: `${map_url}/level_04.svg`,
            floor_type: 'staff',
            settings: {
                zones: ['zone-04.A', 'zone-04.B', 'zone-04.C'],
            },
            desks: 6,
        },
        {
            level_id: 'zone_lvl-05',
            level_name: 'Level 5',
            number: 5,
            map_url: `${map_url}/level_05.svg`,
            floor_type: 'staff',
            settings: {
                zones: ['zone-05.A', 'zone-05.B', 'zone-05.C'],
            },
            desks: 6,
        },
        {
            level_id: 'zone_lvl-06',
            level_name: 'Level 6',
            number: 6,
            map_url: `${map_url}/level_06.svg`,
            floor_type: 'staff',
            settings: {
                zones: ['zone-06.A', 'zone-06.B', 'zone-06.C'],
            },
            desks: 6,
        },
    ];
};

const mockBld = (id: string, name: string): any => {
    return {
        id: id,
        zone_id: id,
        name: name,
        settings: {
            discovery_info: {
                timezone: 'Australia/Sydney',
                phone: {
                    emergency: '0412345678',
                    av_help: '0412345678',
                    concierge: '0412345678',
                },
                locations: {},
                desk_tracking: 'sys-B0',
                systems: {
                    desk_bookings: 'sys-B0',
                    floor_management: 'sys-B0',
                },
                messaging: 'sys-B0',
                features: [],
                catering: {},
                neighbourhoods: {
                    'zone_lvl-01': {
                        'Test Searchable': 'Device_Bench',
                    },
                },
                visitor_space: 'sydney-10.05@acaprojects.com',
                levels: mockLevels(name.toLowerCase()),
                roles: {
                    'fire-warden': [
                        {
                            name: 'Alex Sorafumo',
                            email: `alex.sorafumo@${DOMAIN}`,
                            phone: '0412345678',
                        },
                        { name: 'Bob Jane', email: `bob.jane@${DOMAIN}`, phone: '0423456789' },
                        { name: 'Rupert', email: `rupert@${DOMAIN}`, phone: '0434567890' },
                    ],
                    'first-aider': [
                        { name: 'Ben Hoad', email: `ben.hoad@${DOMAIN}`, phone: '0412345678' },
                        {
                            name: 'Kim Burgess',
                            email: `kim.burgess@${DOMAIN}`,
                            phone: '0423456789',
                        },
                        { name: 'Mr Pop', email: `mr.pop@${DOMAIN}`, phone: '0434567890' },
                    ],
                },
                extras: FEATURES,
                loan_items: [
                    { extra_id: 'ConfKit', extra_name: 'Conference Kit' },
                    { extra_id: 'Chairs', extra_name: 'Chairs' },
                ],
                room_configurations: ROOM_CONFIGS,
            },
        },
    }
};

export const MOCK_BUILDINGS = [
    mockBld('zone_bld-01', 'Dubai'), mockBld('zone_bld-02', 'Olayya')
];
