import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { BaseDirective } from '../../base.directive';
import { ApplicationService } from 'src/app/services/app.service';
import { ApplicationLink } from '../../utilities/settings.interfaces';

@Component({
    selector: 'a-footer-menu',
    templateUrl: './footer-menu.component.html',
    styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent extends BaseDirective implements OnInit {

    /** Tiles to display on the home page */
    public tiles: ApplicationLink[] = [];

    constructor(private _service: ApplicationService) {
        super();
    }

    /** Angular Component initialisation lifecycle hook */
    public ngOnInit(): void {
        this._service.initialised.pipe(first(_ => _)).subscribe(() => {
            this.tiles = this._service.setting('app.home.tiles') || [];
        });
    }
}
