
import { MockHttpRequestHandler } from '@placeos/ts-client';

if (!window.control) {
    window.control = {};
}
if (!window.control.handlers) {
    window.control.handlers = [];
}

export const MOCK_ORGS = [
    { id: 'org-01',  name: 'Ashrae' }
]

export const MOCK_BUILDINGS = [
    { id: 'bld-01', name: 'Building 1' },
];

export const MOCK_LEVELS = [
    { id: 'lvl-01', name: 'Level 1', parent_id: 'bld-01', map_id: 'assets/maps/level_01.svg' },
    { id: 'lvl-02', name: 'Level 2', parent_id: 'bld-01', map_id: 'assets/maps/level_02.svg' },
];

window.control.handlers.push({
    path: '/api/engine/v2/zones',
    metadata: {},
    method: 'GET',
    callback: (request) => {
        if (request.query_params.tags === 'org') {
            return MOCK_ORGS;
        } else if (request.query_params.tags === 'building') {
            return MOCK_BUILDINGS
        } else if (request.query_params.tags === 'level') {
            return MOCK_LEVELS
        }
        throw { status: 404, message: 'Zones not found' }
    }
} as MockHttpRequestHandler);

window.control.handlers.push({
    path: '/api/engine/v2/zones/:id/metadata',
    metadata: {},
    method: 'GET',
    callback: (request) => {
        if (request.query_params.name === 'catering') {
            return [];
        }
        throw { status: 404, message: 'Metadata not found' }
    }
} as MockHttpRequestHandler)
