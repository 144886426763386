<div class="booking-form" [formGroup]="form">
    <div *ngIf="first_form">
        <div>
            <label>
                <strong>Meeting Subject<span>*</span></strong>
            </label>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <input matInput name="title" placeholder="Enter here" formControlName="title" />
            </mat-form-field>
        </div>
        <div>
            <label for="organizer"><strong>Host:<span>*</span></strong></label>
        </div>
        <div>
            <a-user-search-field name="organizer" formControlName="organizer"></a-user-search-field>
        </div>
        <div>
            <label for="start-time"><strong>Time:<span>*</span></strong></label>
        </div>
        <div class="bottom-margin">
            <a-time-field name="start-time" formControlName="date"></a-time-field>
        </div>
        <div>
            <label for="duration"><strong>Duration:<span>*</span></strong></label>
        </div>
        <div>
            <a-duration-field name="duration" formControlName="duration" [is_modal]="true"></a-duration-field>
        </div>
    </div>
    <div [hidden]="first_form">
        <input class="notes" matInput name="notes" placeholder="Add notes here..." formControlName="notes" />
        <div>
            <label for="organiser"><strong>Estimated Headcount:<span>*</span></strong></label>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <input matInput name="headcount" placeholder="Expected attendees here.." formControlName="head_count" />
            </mat-form-field>
        </div>
    </div>
</div>
