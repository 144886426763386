import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { BaseDirective } from 'src/app/shared/base.directive';
import { ApplicationService } from 'src/app/services/app.service';
import { Space } from 'src/app/services/data/spaces/space.class';
import { humaniseDuration } from 'src/app/shared/utilities/general.utilities';

import * as dayjs from 'dayjs';
import { RoomConfiguration } from 'src/app/services/data/shared/room-configuration.interface';
import { ViewCateringModalComponent } from 'src/app/overlays/view-catering-modal/view-catering-modal.component';
import { ViewAttendeesModalComponent } from 'src/app/overlays/view-attendees-modal/view-attendees-modal.component';
import { ViewRoomModalComponent } from 'src/app/overlays/view-room-modal/view-room-modal.component';
import { CalendarService } from 'src/app/services/data/calendar/calendar.service';
import { CalendarEvent } from 'src/app/services/data/events/event.class';
import { OrganisationService } from 'src/app/services/data/organisation/organisation.service';
import { EventsService } from 'src/app/services/data/events/events.service';

@Component({
    selector: 'a-booking-confirm-modal',
    templateUrl: './booking-confirm.component.html',
    styleUrls: ['./booking-confirm.component.scss'],
})
export class BookingConfirmComponent extends BaseDirective {
    /** Emitter for user actions on the modal */
    @Output() public event = new EventEmitter();
    /** Whether changes are being processed */
    public loading: boolean;
    /** Whether space availability is being checked */
    public checking_available: boolean;
    /** Whether to show all attendees */
    public show_attendees: boolean;
    /** Whether to show all attendees */
    public show_locations: boolean;

    /** Booking to confirm changes to */
    public get booking(): CalendarEvent {
        return this._data.booking;
    }

    /** Title of the displayed event */
    public get title(): string {
        return this.booking.title;
    }

    /** Display string of when the event will occur */
    public get when(): string {
        const date = dayjs(this.booking.date);
        const end = date.add(this.booking.duration || 60, 'm');
        if (this.booking.all_day || this.booking.duration > 23 * 60) {
            return `${date.format('DD MMM YYYY')} - All Day`;
        } else {
            if (date.isSame(end, 'd')) {
                return `${date.format('DD MMM YYYY, h:mm A')} - ${end.format('h:mm A')}`;
            } else {
                return `${date.format('DD MMM YYYY, h:mm A')} - ${end.format(
                    'DD MMM YYYY, h:mm A'
                )}`;
            }
        }
    }

    /** Display string for the event's duration */
    public get duration(): string {
        return humaniseDuration(this.booking.duration || 60);
    }

    public get configuration(): RoomConfiguration | null {
        if (!this.booking) {
            return null;
        }
        const configs = this._org.available_room_configs;
        const config = this.booking.configuration;
        return !!config ? configs.find((f) => f.id === config) : null;
    }

    public get catering_items(): any[] {
        const order = this.booking.catering[0];
        return order ? order.items : [];
    }
    public get catering_items_total(): number {
        return this.catering_items.reduce((amount, item) => amount + item.amount, 0);
    }
    public get catering_notes(): string {
        // return this.booking.catering_notes;
        return '';
    }

    constructor(
        private _dialog: MatDialogRef<BookingConfirmComponent>,
        @Inject(MAT_DIALOG_DATA)
        private _data: { old_booking: CalendarEvent; booking: CalendarEvent },
        private _service: ApplicationService,
        private _calendar: CalendarService,
        private _events: EventsService,
        private _org: OrganisationService,
        private _dialogService: MatDialog
    ) {
        super();
    }

    /**
     * Save booking changes
     */
    public saveChanges() {
        this.loading = true;
        this.checking_available = true;
        this.checkSpaceAvailability().then(
            () => {
                this.checking_available = false;
                this.booking.save().then(
                    () => {
                        this.loading = false;
                        this.event.emit({ type: 'success' });
                        this._dialog.close();
                        this._service.notifySuccess(
                            `Successfully ${this.booking.id ? 'updated' : 'created'} booking`
                        );
                        this._service.Analytics.event('Booking', 'book_meeting_success');
                    },
                    (err) => {

                        this.loading = false;
                        this._service.notifyError(
                            `Error ${this.booking.id ? 'updating' : 'creating'} booking: ${err}`
                        );
                        this._service.Analytics.event('Booking', 'book_meeting_failed');
                    }
                );
            },
            (err) => {
                this.loading = false;
                this.checking_available = false;
                this._service.notifyError(`Error: ${err}`);
                this._service.Analytics.event('Booking', 'book_meeting_failed');
            }
        );
    }

    /**
     * Check whether the selected spaces are available for the selected time period
     */
    public checkSpaceAvailability(): Promise<void> {
        const spaces: Space[] = this.booking.resources;
        if (
            (this.booking.id && this._data.old_booking.date !== this.booking.date) ||
            this._data.old_booking.duration !== this.booking.duration ||
            (spaces && spaces.length <= 0)
        ) {
            console.log('No check needed')
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            const date = dayjs(this.booking.date);
            this._calendar
                .availability({
                    system_ids: spaces.map((space) => space.id).join(','),
                    period_start: date.unix(),
                    period_end: date.add(this.booking.duration, 'm').unix()
                })
                .then(
                    (cal_list) => {
                        for (const space of spaces) {
                            if (!cal_list.find((cal) => cal.resource.id === space.id)) {
                                return reject(
                                    `${space.name} is not available at the select time period.`
                                );
                            }
                        }
                        resolve();
                    },
                    (err) => reject(err)
                );
        });
    }

    public showCatering() {
        this._dialogService.open(ViewCateringModalComponent, {
            width: '32em',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { catering: this.catering_items, catering_note: this.catering_notes },
        });
    }

    public showAttendees() {
        this._dialogService.open(ViewAttendeesModalComponent, {
            width: '32em',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { attendees: this.booking.attendees },
        });
    }

    public showSpace(space: Space) {
        this._dialogService.open(ViewRoomModalComponent, {
            width: '40vw',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: { space },
        });
    }
}
