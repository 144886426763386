import { MockHttpRequestHandler } from '@placeos/ts-client';

import { ACTIVE_USER, MOCK_STAFF } from './users.mock';
import { MOCK_SPACES } from './systems.mock';

import * as dayjs from 'dayjs';
import * as faker from 'faker';

let EVENT_TIME = dayjs().startOf('d').hour(7);

export const MOCK_EVENTS = new Array(200).fill(0).map((_, index) => {
    EVENT_TIME = EVENT_TIME.add(Math.floor(Math.random() * 8 + 1) * 15, 'm');
    let attendees: any[] = MOCK_STAFF.filter((_) => Math.floor(Math.random() * 9999) % 3 === 0);
    if (attendees.length <= 0) {
        attendees.push(MOCK_STAFF[Math.floor(Math.random() * MOCK_STAFF.length)]);
    }
    attendees[Math.floor(Math.random() * attendees.length)].organizer = true;
    attendees = attendees.concat(
        MOCK_SPACES.filter((_) => Math.floor(Math.random() * 9999) % 5 === 0).map((space) => ({
            ...space,
            resource: true,
        }))
    );
    if (Math.floor(Math.random() * 9999) % 10 === 0) {
        attendees.push(ACTIVE_USER);
    }
    return {
        id: `cal-event-${index}`,
        event_start: EVENT_TIME.unix(),
        event_end: EVENT_TIME.add(Math.floor(Math.random() * 8 + 1) * 15, 'm').unix(),
        title: `${faker.commerce.productName()} Meeting`,
        body: faker.lorem.paragraph(),
        attendees,
    };
});

window.control.handlers.push({
    path: '/api/staff/v1/events',
    metadata: {},
    method: 'GET',
    callback: (_) => {
        console.log('Event count:', MOCK_EVENTS.length);
        const events = MOCK_EVENTS.filter(
            (event) => !!event.attendees.find((user) => user.email === ACTIVE_USER.email)
        );
        return events;
    },
} as MockHttpRequestHandler);

window.control.handlers.push({
    path: '/api/staff/v1/events',
    metadata: {},
    method: 'POST',
    callback: (request) => {
        const new_event = { ...request.body, id: `-cal-event-${Math.floor(Math.random() * 999)}` };
        new_event.attendees.forEach(user => {
            if (user.zones) {
                user.resource = true;
            }
        });
        MOCK_EVENTS.push(new_event);
        return new_event;
    },
} as MockHttpRequestHandler);

window.control.handlers.push({
    path: '/api/staff/v1/events/:id',
    metadata: {},
    method: 'GET',
    callback: (request) => {
        const item = MOCK_EVENTS.find((event) => event.id === request.route_params.id);
        if (item) {
            return item;
        }
        throw { status: 404, message: 'Event not found' };
    },
} as MockHttpRequestHandler);

window.control.handlers.push({
    path: '/api/staff/v1/events/:id',
    metadata: {},
    method: 'PATCH',
    callback: (request) => {
        const index = MOCK_EVENTS.findIndex((event) => event.id === request.route_params.id);
        if (index >= 0) {
            return MOCK_EVENTS.splice(index, 1, request.body);
        }
        throw { status: 404, message: 'Event not found' };
    },
} as MockHttpRequestHandler);
