import { Component, OnInit, Input } from '@angular/core';
import { ApplicationIcon } from 'src/app/shared/utilities/settings.interfaces';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'a-yes-no-radio',
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss']
})
export class YesNoRadioComponent implements OnInit {
  @Input() public control: FormControl;
  private rndId = '';
  public idYes = '';
  public idNo = '';

  constructor() { }

  public ngOnInit(): void {
    this.rndId = Math.random().toString(36).substring(7);
    this.idYes = `yes_${this.rndId}`;
    this.idNo = `no_${this.rndId}`;
  }

  public get isYes(): boolean { return !!this.control && !!this.control.value; }

}
