import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseDirective } from 'src/app/shared/base.directive';
import { generateUserForm } from 'src/app/services/data/users/user.utilities';
import { User } from 'src/app/services/data/users/user.class';
import { DialogEvent } from '../../shared/utilities/types.utilities';

@Component({
  selector: 'new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss']
})
export class NewUserModalComponent extends BaseDirective implements OnInit {
    /** Emitter for user action on the modal */
    @Output() public event = new EventEmitter<DialogEvent>();
    /** Form fields for the new user */
    public form: FormGroup;
    /** New user data store */
    public user: User;
    /** Whether user details are being saved */
    public loading: boolean;

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.user = new User({});
        this.form = generateUserForm(this.user);
    }

    public saveChanges() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const new_user = new User({
                ...this.form.value,
                name: `${this.form.value.first_name} ${this.form.value.last_name}`,
                type: 'external'
            });
            this.event.emit({ reason: 'done', metadata: new_user });
        }
    }

}
