
import { MockHttpRequestHandler } from '@placeos/ts-client';
import { createSystem } from '../systems-bindings.mock';

if (!window.control.systems) {
    window.control.systems = {};
}

export const MOCK_SPACES: any[] = [
    { name: 'ASH-01.14 Collaboration Room', map_id: 'area-01.14-status', zones: ['bld-01', 'lvl-01'] },
    { name: 'ASH-01.07 Meeting Room', map_id: 'area-01.07-status', zones: ['bld-01', 'lvl-01'] },
    { name: 'ASH-01.15 Collaboration Room', map_id: 'area-01.15-status', zones: ['bld-01', 'lvl-01'] },
    { name: 'ASH-01.33 Induction Room', map_id: 'area-01.33-status', zones: ['bld-01', 'lvl-01'] },
    { name: 'ASH-01.34 Induction Room', map_id: 'area-01.34-status', zones: ['bld-01', 'lvl-01'] },
    { name: 'ASH-02.33 Multi-Purpose Room', map_id: 'area-02.33-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.34 Multi-Purpose Room', map_id: 'area-02.34-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.21 Multi-Purpose Room', map_id: 'area-02.21-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.32 Multi-Purpose Room', map_id: 'area-02.32-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.27 Multi-Purpose Area', map_id: 'area-02.27-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.31 Multi-Purpose Area', map_id: 'area-02.31-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.25 Multi-Purpose Area', map_id: 'area-02.25-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.26 Multi-Purpose Area', map_id: 'area-02.26-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.24 Multi-Purpose Room', map_id: 'area-02.24-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-02.22 Multi-Purpose Room', map_id: 'area-02.22-status', zones: ['bld-01', 'lvl-02'] },
    { name: 'ASH-EmaarSquare-08.138 Leadership Room', map_id: 'area-08.138-status', capacity: 8, zones: ['bld-01', 'lvl-02'] }
];

MOCK_SPACES.forEach((space: any, index) => {
    space.id = `sys-mock-${index}`;
    space.bookable = true;
    space.capacity = space.capacity || Math.floor(Math.random() * 5 + 1) * 4;
    window.control.systems[space.id] = createSystem(space.id);
});

window.control.handlers.push({
    path: '/api/engine/v2/systems',
    metadata: {},
    method: 'GET',
    callback: (request) => {
        return MOCK_SPACES
    }
} as MockHttpRequestHandler)
