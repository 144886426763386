import { Injectable } from '@angular/core';
import { ComposerService } from '@placeos/composer';
import { EngineHttpClient } from '@placeos/ts-client';
import { StaffUser } from './user.class';
import { ServiceManager } from '../service-manager.class';
import { first } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class StaffService {
    private _route: string = '/api/staff/v1/people';
    /** Currently logged in user */
    private _active_user: StaffUser;

    /** Currently logged in user */
    public get current(): StaffUser {
        return this._active_user;
    }

    private get _http(): EngineHttpClient {
        return this._composer.http;
    }

    constructor(private _composer: ComposerService) {
        ServiceManager.setService(StaffUser, this);
        this._active_user = new StaffUser({ name: 'Local User' });
        this._composer.initialised.pipe(first(_ => _)).subscribe(() => {
            this._composer.users.show('current').then((user) => {
                if (user) {
                    this._active_user = new StaffUser({ ...user, is_logged_in: true });
                }
            });
        })
    }

    /**
     * List staff members
     * @param q Filter string for staff
     */
    public list(q: string): Promise<StaffUser[]> {
        return new Promise((resolve, reject) => {
            const url = `${this._route}${q ? '?q=' +  encodeURIComponent(q) : ''}`;
            let items = [];
            this._http.get(url).subscribe(
                (list) => (items = list.map((user) => new StaffUser(user))),
                (err) => reject(err),
                () => resolve(items)
            );
        });
    }

    /**
     * Get location of a staff member
     * @param id Email address of the staff member
     */
    public locate(id: string): Promise<StaffUser> {
        return new Promise((resolve, reject) => {
            const url = `${this._route}/${encodeURIComponent(id)}`;
            let item = null;
            this._http.get(url).subscribe(
                (user) => item = new StaffUser(user),
                (err) => reject(err),
                () => resolve(item)
            );
        });
    }
}
