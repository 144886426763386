<div class="user-search-field" form-field>
    <mat-form-field appearance="outline">
        <input
            matInput
            name="user-search"
            [(ngModel)]="search_str"
            (ngModelChange)="search$.next($event)"
            placeholder="Search for user..."
            [matAutocomplete]="auto"
            (blur)="resetSearchString()"
        />
        <div class="prefix" matPrefix>
            <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'search' }"></app-icon>
        </div>
        <div class="suffix" matSuffix *ngIf="loading">
            <mat-spinner diameter="16"></mat-spinner>
        </div>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
        <mat-option *ngFor="let option of user_list" [value]="option">
            <div class="name">{{ option.name }}</div>
            <div class="email">{{ option.email }}</div>
        </mat-option>
    </mat-autocomplete>
</div>
