import { HashMap } from 'src/app/shared/utilities/types.utilities';

import { ServiceManager } from '../service-manager.class';
import { User, StaffUser } from '../users/user.class';
import { Space } from '../spaces/space.class';
import { unique } from 'src/app/shared/utilities/general.utilities';
import { CateringOrder } from '../catering/catering-order.class';

import * as dayjs from 'dayjs';

export interface FileDetails {
    /** Name of the file */
    name: string;
    /** Blob contents of the file */
    blob: Blob;
}

export interface RecurrenceDetails {
    range_start: number;
    range_end: number;
    days_of_week: number;
    interval: number;
    pattern: number
}


export class CalendarEvent<T extends HashMap = any> {
    /** UID of the event */
    public readonly id: string;
    /** Status of the event */
    public readonly status: string;
    /** Email address of the host */
    public readonly host: string;
    /** Email address of the host */
    public readonly organizer: StaffUser;
    /** ID of the calendar associated with the event */
    public readonly calendar: string;
    /** Email address of the event creator */
    public readonly creator: string;
    /** List of attendees of the event */
    public readonly attendees: User[];
    /** List of attendees of the event */
    public readonly resources: Space[];
    /** Summary of the event details */
    public readonly title: string;
    /** Extended details of the event */
    public readonly body: string;
    /** Whether event details should be private */
    public readonly private: boolean;
    /** Whether event occurs over the full day */
    public readonly all_day: boolean;
    /** Unix epoch of the start time of the event */
    public readonly date: number;
    /** Duration of the event in minutes */
    public readonly duration: number;
    /** IANA timezone string for the event location */
    public readonly timezone: string;
    /** Location details for the event */
    public readonly location: string;
    /** Whether this event is recurring */
    public readonly recurring: boolean;
    /** Details about the event's recurrence */
    public readonly recurrence: RecurrenceDetails;
    /** File attachements for the event */
    public readonly attachments: FileDetails[];
    /** Extra data associated with the event */
    public readonly extension_data: T;

    /** List of catering orders for event */
    public get catering(): CateringOrder[] {
        return this.extension_data.catering || [];
    }

    /** List of catering orders for event */
    public get configuration(): string {
        return this.extension_data.configuration;
    }

    /** Whether a parking spot is needed for attendees */
    public get needs_parking(): boolean {
        return this.extension_data.needs_parking;
    }

    /** Whether booking has catering */
    public get has_catering(): boolean {
        return this.extension_data.catering && !!this.extension_data.catering.length;
    }

    /** Link associated with the event meeting */
    public get meeting_link(): string {
        return this.extension_data.meeting_link;
    }

    public get has_visitors(): boolean {
        return !!this.attendees.find(user => user.visit_expected)
    }

    private get _service(): any {
        return ServiceManager.serviceFor(CalendarEvent);
    }

    constructor(data: HashMap = {}) {
        this.id = data.id || '';
        this.status = data.status || '';
        this.host = data.host || '';
        this.calendar = data.calendar || '';
        this.creator = data.creator || '';
        const attendees = data.attendees || [];
        this.attendees = attendees.filter(user => !user.resource).map(data => new User(data));
        this.resources = data.resources || attendees.filter(user => user.resource).map(data => new Space(data));
        this.organizer = data.organizer || attendees.find(user => user.email === this.host);
        this.title = data.title || '';
        this.body = data.body || '';
        this.private = !!data.private;
        this.all_day = !!data.all_day;
        const start = dayjs(data.event_start * 1000 || data.date);
        this.date = start.valueOf();
        this.duration = data.duration || dayjs(data.event_end * 1000).diff(start, 'm') || 60;
        this.timezone = data.timezone;
        this.location = data.location || '';
        this.recurring = !!data.recurring;
        this.recurrence = data.recurrence || {};
        this.attachments = data.attachements || [];
        this.extension_data = data.extension_data || {};
        (this.extension_data as any).catering = this.extension_data.catering || data.catering;
        (this.extension_data as any).configuration = this.extension_data.configuration || data.configuration || '';
        (this.extension_data as any).meeting_link = this.extension_data.meeting_link || data.meeting_link || '';
        (this.extension_data as any).need_parking = this.extension_data.need_parking || data.need_parking || '';
    }

    /**
     * Save state of event
     */
    public save() {
        console.log('ID:', this.id);
        return !this.id
            ? this._service.add(this.toJSON())
            : this._service.update(this.toJSON());
    }

    /**
     * Convert class data to simple JSON object
     */
    public toJSON(): HashMap {
        const obj: HashMap = { ...this };
        const end = dayjs(this.date).add(this.duration, 'm').unix();
        obj.attendees =
        obj.event_start = Math.floor(this.date / 1000);
        obj.event_end = end;
        const attendees = ([this.organizer] as any[]).concat(this.resources).concat(this.attendees);
        obj.attendees = unique(attendees, 'email')
        delete obj.date;
        delete obj.duration;
        delete obj.resources;
        delete obj.organizer;
        return obj;
    }
}
