<div class="duration-field">
    <mat-form-field appearance="outline" class="full-width" *ngIf="is_modal">
        <mat-select #select [value]="duration" (valueChange)="setValue($event)">
            <mat-option *ngFor="let option of duration_options" [value]="option.value">
                {{ option.display }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="!is_modal">
        <mat-select #select [value]="duration" (valueChange)="setValue($event)">
            <mat-option *ngFor="let option of duration_options" [value]="option.value">
                {{ option.display }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>