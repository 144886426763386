
import { createSystem } from './systems-bindings.mock';

window.control = window.control || {};
window.control.systems = {
    ...window.control.systems,
    ['sys-B0']: createSystem('sys-B0')
} || {};
window.control.handlers = window.control.handlers || [];

import './api/zones.mock';
import './api/users.mock';
import './api/systems.mock';
import './api/events.mock';
import './api/calendars.mock';
