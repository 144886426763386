<button
    mat-icon-button
    [class.small]="true"
    *ngFor="let item of actions; let i = index"
    [style.top]="show ? (-110 * i - 60) + '%' : ''"
    (tapped)="action.emit(item.id); show = false"
>
    <app-icon [icon]="item"></app-icon>
</button>
<button mat-icon-button [class.show]="show" (tapped)="show = !show" (window:click)="show ? close() : ''">
    <app-icon [icon]="{ class: 'material-icons', content: show ? 'add' : 'more_vert' }"></app-icon>
</button>
