import { HashMap } from 'src/app/shared/utilities/types.utilities';

export type EventResponseStatus = 'needsAction' | 'declined' | 'tentative' | 'accepted';

export class User<T extends {} = HashMap> {
    /** Name of the user */
    public readonly name: string;
    /** Email address of the user */
    public readonly email: string;
    /** Phone number of the user */
    public readonly phone: string;
    /** Organisation associated with the user */
    public readonly organisation: string;
    /** Details about the user */
    public readonly notes: string;
    /** URL to the user's photo */
    public readonly photo: string;
    /** Whether user is the event organiser */
    public readonly organizer: boolean;
    /** Whether the users is expected for an event */
    public readonly response_status: EventResponseStatus;
    /** Whether the users is expected for an event */
    public readonly visit_expected?: boolean;
    /** Extra metadata associated with the user */
    public readonly extension_data: T;

    constructor(data: HashMap = {}) {
        this.name = data.name || '';
        this.email = data.email || '';
        this.phone = data.phone || '';
        this.organisation = data.organisation || '';
        this.notes = data.notes || '';
        this.photo = data.photo || '';
        this.organizer = !!data.organizer;
        this.extension_data = data.extension_data || {};
        this.visit_expected = !!data.visit_expected;
        this.response_status = data.response_status || 'tentative';
    }
}

export class GuestUser<T extends {} = HashMap> extends User<T> {
    /** Whether user has been checked in */
    public readonly checked_in: boolean;
    /** Whether the users is expected for an event */
    public readonly visit_expected: boolean;

    constructor(data: HashMap = {}) {
        super(data);
        this.checked_in = !!data.checked_in;
    }
}

export class StaffUser<T extends {} = HashMap> extends User<T> {
    /** Department that the user belongs to in the organisation */
    public readonly department: string;
    /** Number associated with the user's access card */
    public readonly card_number: number;
    /** ID of the user */
    public readonly staff_id: string;
    /** Whether user is logged in */
    public readonly is_logged_in: boolean;
    /** Location of the user */
    public readonly location: HashMap;

    constructor(data: HashMap = {}) {
        super(data);
        this.department = data.department || '';
        this.card_number = data.card_number || 0;
        this.staff_id = data.staff_id || '';
        this.location = data.location || {};
        this.is_logged_in = !!data.is_logged_in;
    }
}
