
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedContentModule } from '../shared/shared.module';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ViewRoomModalComponent } from './view-room-modal/view-room-modal.component';

import { BookingConfirmComponent } from '../shell/bookings/overlays/booking-confirm/booking-confirm.component';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';
import { SpaceSelectModalComponent } from './space-select-modal/space-select-modal.component';
import { ItemComponent } from './space-select-modal/item/item.component';
import { ViewAttendeesModalComponent } from './view-attendees-modal/view-attendees-modal.component';
import { ViewCateringModalComponent } from './view-catering-modal/view-catering-modal.component';
import { RecurrenceModalComponent } from './recurrence-modal/recurrence-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const OVERLAYS: Type<any>[] = [
    ConfirmModalComponent,
    ViewRoomModalComponent,
    BookingConfirmComponent,
    NewUserModalComponent,
    ViewAttendeesModalComponent,
    ViewCateringModalComponent,
    RecurrenceModalComponent
];

@NgModule({
    declarations: [
        ...OVERLAYS,
        SpaceSelectModalComponent,
        ItemComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedContentModule
    ],
    exports: [
        ...OVERLAYS
    ]
})
export class SharedOverlaysModule {}
