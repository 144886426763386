import { BaseClass } from 'src/app/shared/base.class';
import { Injectable } from '@angular/core';
import { ComposerService } from '@placeos/composer';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { OrganisationService } from '../organisation/organisation.service';
import { Space } from './space.class';

@Injectable({
    providedIn: 'root'
})
export class SpacesService extends BaseClass {
    /** Subject to store list of spaces */
    private _list_subject = new BehaviorSubject<Space[]>([]);
    /** Default predicate for filter method */
    private _compare = (space: Space) => space.zones.includes(this._org.building.id);

    /** List of available spaces */
    public get space_list(): Space[] {
        return this._list_subject.getValue();
    }

    constructor(private _composer: ComposerService, private _org: OrganisationService) {
        super();
        this._org.initialised.pipe(first(_ => _)).subscribe(() => this.loadSpaces());
    }

    /**
     * Get a filtered list of the available spaces
     * @param predicate Predicate for filtering spaces
     */
    public filter(predicate: (_: Space) => boolean = this._compare) {
        return this._list_subject.getValue().filter(_ => predicate(_));
    }

    /**
     * Find space with given id/email
     * @param id ID/Email address associated with the space
     */
    public find(id: string) {
        return this._list_subject.getValue().find(space => space.id === id || space.email === id);
    }

    private async loadSpaces(): Promise<void> {
        console.log('Organisation:', this._org.organisation);
        const systems = await this._composer.systems.query({ zone_id: this._org.organisation.id, limit: 5000 });
        const space_list = systems.map(sys => new Space({ ...sys }));
        // Remove spaces without a map ID
        const valid_spaces = space_list.filter(space => space.map_id);
        this._list_subject.next(valid_spaces);
        this._initialised.next(true);
    }
}
