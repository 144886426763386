import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseDirective } from '../../../base.directive';

@Component({
    selector: 'a-booking-form-modal',
    templateUrl: './booking-form-modal-component.html',
    styleUrls: ['./booking-form-modal-component.scss']
})
export class BookingFormModalComponent extends BaseDirective implements OnInit {
    @Input() public first_form: boolean;
    @Input() public form: FormGroup;

    public ngOnInit() {
        if (!this.form) {
            this.timeout('init', this.ngOnInit);
            return;
        }
    }
}