
import { HashMap } from '../../../shared/utilities/types.utilities';
import { getItemWithKeys } from '../../../shared/utilities/general.utilities';
import { BaseAPIService } from '../base.service';
import { ServiceManager } from '../service-manager.class';

export class Organisation {
    /** Name of the Organisation */
    public readonly name: string;
    /** Description for the Organisation */
    public readonly description: string;
    /** Tags for the Organisation */
    public readonly tags: string;
    /** Count for the Organisation */
    public readonly count: number;
    /** Capacity for the Organisation */
    public readonly capacity: number;
    /** Parent ID for the Organisation */
    public readonly _parent_id: string;
    /** ID of the Organisation */
    public readonly id: string;
    /** Map of custom settings for the building */
    private _settings: HashMap;

    /** Service associated with Booking */
    protected get _service(): BaseAPIService<Organisation> {
        return ServiceManager.serviceFor(Organisation);
    }

    constructor(raw_data: HashMap = {}) {
        this.id = raw_data.id;
        this.name = raw_data.name;
        this.description = raw_data.description;
        this.tags = raw_data.tags;
        this.count = raw_data.count;
        this.capacity = raw_data.capacity;
        this._settings = raw_data.settings || {};
    }

    /**
     * Get a custom organisation setting
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        const keys = key.split('.');
        const value = getItemWithKeys(keys, this._settings);
        return value;
    }
}
