import { BaseDataClass } from '../base-api.class';
import { HashMap, Identity } from '../../../shared/utilities/types.utilities';
import { getItemWithKeys } from '../../../shared/utilities/general.utilities';
import { BuildingLevel } from './level.class';
import { BookingRule } from '../bookings/booking.interfaces';
import { RoomConfiguration } from '../shared/room-configuration.interface';

export interface IBuildingRoleUser {
    name: string;
    email: string;
    phone: string;
}

export interface LockerMap {
    [zone: string]: {
        [area: string]: {
            [type: string]: (string | boolean)[][];
        };
    };
}

export interface ICoordinates {
    longitude: number;
    latitude: number;
}

export interface LevelFeature {
    id: string;
    level_id: string;
    name: string;
}

export interface BookingRuleDetails {
    /** List of booking rules details for the building */
    readonly rules: readonly string[];
    /** Custom booking rules for the map */
    readonly map_rules?: readonly string[];
    /** Contact email address for the building */
    readonly contact?: string;
    /** Information string to display before the rule listings */
    readonly info?: string;
}

export class Building extends BaseDataClass {
    /** Engine Zone ID for the building */
    public readonly zone_id: string;
    /** Organisation Code for the building */
    public readonly code: string;
    /** Geographical address of the building */
    public readonly address: string;
    /** Details about the booking rules for the building */
    public readonly booking_details: BookingRuleDetails;
    /** Details about the booking rules for the building */
    public readonly booking_rules: HashMap<readonly BookingRule[]>;
    /** Number of hour before a booking catering is restricted */
    public readonly catering_restricted_from: number;
    /** Currency code for the country assoicated with the building */
    public readonly currency: string;
    /** List of available extras for the building */
    private _extras: Identity[];
    /** List of available extra equipment for loan at the building */
    private _loan_items: Identity[];
    /** List of available levels for the building */
    private _levels: BuildingLevel[];
    /** Map of custom settings for the building */
    private _settings: HashMap;
    /** Map of roles and list of the associated users */
    private _roles: HashMap<IBuildingRoleUser[]>;
    /** Map of the locker ID arrays */
    private _lockers: LockerMap;
    /** Map of important system ids for the building */
    private _systems: HashMap<string>;
    /** Map of important phone numbers for the building */
    private _phone_numbers: HashMap<string>;
    /** Globe coordiates for the build */
    private _location: ICoordinates;
    /** List of zones associated with the building */
    private _zones: string[];
    /** Searchable map features */
    private _searchables: LevelFeature[];

    private _room_configurations: RoomConfiguration[];

    constructor(raw_data: HashMap) {
        super(raw_data);
        const settings = raw_data.settings || {};
        const disc_info = settings.discovery_info || settings;
        this.zone_id = raw_data.zone_id || raw_data.zone;
        this._extras = (raw_data.extras || disc_info.extras || []).map(i => ({ id: i.extra_id || i.id, name: i.extra_name || i.name }));
        this._loan_items = (raw_data.loan_items || disc_info.loan_items  || []).map(i => ({ id: i.extra_id || i.id, name: i.extra_name || i.name }));
        this._levels = (raw_data.levels || disc_info.levels  || []).map(i => new BuildingLevel({ ...i, building_id: this.id }));
        this._roles = raw_data.roles || disc_info.roles || {};
        this._lockers = raw_data.lockers || raw_data.locker_structure || disc_info.locker_structure || {};
        this._systems = raw_data.systems || disc_info.systems || {};
        this._settings = settings;
        this._phone_numbers = raw_data.phone_numbers || disc_info.phone_numbers || {};
        this._location = raw_data.location || disc_info.location || { longitude: null, latitude: null };
        this._room_configurations = raw_data.room_configurations || disc_info.room_configurations || [];
        this._searchables = [];
        if (raw_data.neighbourhoods) {
            for (const lvl in raw_data.neighbourhoods) {
                if (raw_data.neighbourhoods.hasOwnProperty(lvl)) {
                    const lvl_features = raw_data.neighbourhoods[lvl] || {};
                    for (const feature in lvl_features) {
                        if (lvl_features.hasOwnProperty(feature)) {
                            this._searchables.push({
                                id: lvl_features[feature],
                                name: feature,
                                level_id: lvl
                            });
                        }
                    }
                }
            }
        }
        this.code = raw_data.code || disc_info.code || settings.code || '';
        this.address = raw_data.address || disc_info.address || settings.address || '';
        this.booking_details = raw_data.booking_details || disc_info.booking_details || settings.booking_details || null;
        this.booking_rules = raw_data.booking_rules || disc_info.booking_rules || settings.booking_rules || {};
        this.catering_restricted_from = raw_data.catering_restricted_from || disc_info.catering_restricted_from || settings.catering_restricted_from || -1440;
        this.currency = raw_data.currency || disc_info.currency || settings.currency || 'USD';
    }

    /** List of available extras for the building */
    public get extras(): Identity[] {
        return [...(this._extras || [])];
    }
    /** List of available extras for the building */
    public get loan_items(): Identity[] {
        return [...(this._loan_items || [])];
    }
    /** List of available levels for the building */
    public get levels(): BuildingLevel[] {
        return [...(this._levels || [])];
    }
    /**
     * Get a custom building setting
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        const keys = key.split('.');
        const value = getItemWithKeys(keys, this._settings);
        return value;
    }

    /**
     * Get list of users with the associated role
     * @param name Role to find users for
     */
    public role(name: string): IBuildingRoleUser[] {
        return [...(this._roles[name] || [])];
    }
    /**
     * Get list of the names of available user role lists
     */
    public get role_names(): string[] {
        return Object.keys(this._roles).filter(i => this._roles.hasOwnProperty(i));
    }
    /** Map of the locker ID arrays */
    public get lockers(): LockerMap {
        return { ...(this._lockers || {}) };
    }
    /** Map of important system ids for the building */
    public get systems(): HashMap<string> {
        return { ...(this._systems || {}) };
    }
    /** Map of important phone numbers for the building */
    public get phone_numbers(): HashMap<string> {
        return { ...(this._phone_numbers || {}) };
    }
    /** Real coordinates */
    public get location(): ICoordinates {
        return { ...this._location };
    }
    /** List of zones associated with the building */
    public get zones(): string[] {
        return [...(this._zones || [])];
    }
    /** Searchable map features */
    public get searchables(): LevelFeature[] {
        return [...(this._searchables || [])];
    }

    public get room_configurations(): RoomConfiguration[] {
        return [...(this._room_configurations || [])];
    }

    /**
     * Get search map feature for the given level ID
     * @param level_id ID of level to grab features for
     */
    public featuresForLevel(level_id: string): LevelFeature[] {
        return (this._searchables || []).filter(i => i.level_id === level_id);
    }

    /**
     * Building objects are readonly and cannot be changed
     */
    public async save(): Promise<Building> {
        throw new Error('Building objects are readonly and cannot be changed');
    }

    /**
     * Building objects are readonly and cannot be deleted
     */
    public async delete(): Promise<void> {
        throw new Error('Building objects are readonly and cannot be deleted');
    }
}
