import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { ApplicationService } from 'src/app/services/app.service';
import { BaseDirective } from 'src/app/shared/base.directive';
import { HomePageSettings, ApplicationLink } from 'src/app/shared/utilities/settings.interfaces';
import { SpacesService } from 'src/app/services/data/spaces/spaces.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseDirective implements OnInit {
    /** Settings for the home page */
    public settings: HomePageSettings;
    /** Whether to show the overlay menu */
    public show_menu: boolean;
    /** TO BE REMOVED, DUMMY DATA */
    public dummy_users = [
        {
            name: 'John Smith',
            url: 'assets/img/avatar.jpg',
            location: 'Lvl 10',
            online: false,
        },
        {
            name: 'Karen Page',
            url: 'assets/img/avatar.jpg',
            location: 'Lvl 08',
            online: true,
        },
        {
            name: 'Kathryn Zimmerman',
            url: 'assets/img/avatar.jpg',
            location: 'Lvl 11',
            online: true,
        },
        {
            name: 'Kate Smith',
            url: 'assets/img/avatar.jpg',
            location: 'Lvl 11',
            online: true,
        },
    ];
    public rooms;

    constructor(
        private _service: ApplicationService,
        private _spaces: SpacesService
    ) {
        super();
    }

    public ngOnInit(): void {
        this._service.title = 'Home';
        this._service.navTitle = 'Home';
        this._service.initialised.pipe(first((_) => _)).subscribe(() => {
            this.settings = this._service.setting('app.home') || {};
        });
        this._spaces.initialised.subscribe((state) => {
            if (state) {
                this.rooms = this._spaces.space_list.slice(0, 2);
            }
        });
    }
}
