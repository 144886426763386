<div class="home page">
    <header>
        <a-topbar-header [(menu)]="show_menu"></a-topbar-header>
    </header>
    <main class="body">
        <div class="overlay">
            <div
                class="block background"
                [style.background-image]="'url(' + settings?.background + ')' | safe: 'style'"
            ></div>
        </div>
        <div class="top">
            <div class="row mb message">
                Good Morning
            </div>
            <div class="row mb">
                <div class="icon">
                    <i class="material-icons-outlined">grade</i>
                </div>
                <div class="text">Most free space:</div>
                <div class="items">
                    <a [routerLink]="['/explore']" [queryParams]="{ level: 'lvl-01' }">
                        <button mat-button>Lvl 1</button>
                    </a>
                </div>
            </div>
            <div class="row mb">
                <div class="icon">
                    <i class="material-icons">av_timer</i>
                </div>
                <div class="text">Free rooms now:</div>
                <div class="items">
                    <a
                        *ngFor="let space of rooms"
                        [routerLink]="['/explore']"
                        [queryParams]="{ space: space.id }"
                    >
                        <button mat-button>{{ space.short_map_id }}</button>
                    </a>
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="card">
                <div class="header">
                    Next Meeting
                    <button mat-icon-button>
                        <i class="material-icons">more_vert</i>
                    </button>
                </div>
                <div class="content">
                    <div class="time">
                        <div class="icon">
                            <i class="material-icons">watch_later</i>
                        </div>
                        10:30 AM
                    </div>
                    <div class="details">Sales Meeting</div>
                    <div class="details">11.08</div>
                    <div class="visitors">
                        <div class="row">
                            <div class="icon">
                                <i class="material-icons">group</i>
                            </div>
                            Visitors x3
                        </div>
                        <div class="row">
                            <div class="participant">JR</div>
                            <div class="participant">CR</div>
                            <div class="participant">DA</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="header">
                Your team
                <button mat-icon-button>
                    <i class="material-icons">more_vert</i>
                </button>
            </div>
            <div class="members">
                <div class="member" *ngFor="let user of dummy_users">
                    <div class="avatar">
                        <img [src]="user.url" alt="avatar" />
                    </div>
                    <div class="name">{{ user.name }}</div>
                    <div class="location">
                        <div class="dot" [class.online]="user.online"></div>
                        {{ user.location }}
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <div class="icon">
                            <i class="material-icons">person_add</i>
                        </div>
                    </div>
                    <div class="location">
                        Click to add a person to teams
                    </div>
                </div>
            </div>
        </div>
    </main>
    <div class="footer">
        <div *ngFor="let tile of settings?.tiles" class="tile">
            <a [routerLink]="tile.route">
                <button mat-icon-button>
                    <i [class]="tile.icon.class">{{ tile.icon.content }}</i>
                </button>
            </a>
            <div>{{ tile.name }}</div>
        </div>
    </div>
</div>
<a-overlay-menu [(show)]="show_menu"></a-overlay-menu>
