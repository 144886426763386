
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ComposerModule } from '@placeos/composer';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { APipesModule } from '@acaprojects/ngx-pipes';
import { AInteractiveMapModule } from '@acaprojects/ngx-interactive-map';
import { ACustomEventsModule } from '@acaprojects/ngx-custom-events';
import { ADatePickerModule } from '@acaprojects/ngx-date-picker';

import { BaseDirective } from './base.directive';
import { UserSearchFieldComponent } from './components/custom-fields/user-search-field/user-search-field.component';
import { TimeFieldComponent } from './components/custom-fields/time-field/time-field.component';
import { DurationFieldComponent } from './components/custom-fields/duration-field/duration-field.component';
import { LoginComponent } from './components/login/login.component';
import { IconComponent } from './components/icon/icon.component';
import { TopbarHeaderComponent } from './components/topbar-header/topbar-header.component';
import { OverlayMenuComponent } from './components/overlay-menu/overlay-menu.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { DateFieldComponent } from './components/custom-fields/date-field/date-field.component';
import { ActionFieldComponent } from './components/custom-fields/action-field/action-field.component';
import { UserListFieldComponent } from './components/custom-fields/user-list-field/user-list-field.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

import { UserFormComponent } from './components/forms/user-form/user-form.component';
import { BookingFormComponent } from './components/forms/booking-form/booking-form.component';

import { MapControlsComponent } from './components/map-controls/map-controls.component';
import { PopoutMenuComponent } from './components/popout-menu/popout-menu.component';
import { BookingFormModalComponent } from './components/forms/booking-form-modal/booking-form-modal-component';
import { EventViewComponent } from '../shell/schedule/view-event/event-view/event-view.component';
import { BookingEditComponent } from './components/forms/booking-edit/booking-edit.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { YesNoRadioComponent } from './components/custom-fields/yes-no-radio/yes-no-radio.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';

const COMPONENTS: Type<any>[] = [
    BaseDirective,
    LoginComponent,
    IconComponent,
    TopbarHeaderComponent,
    OverlayMenuComponent,
    FooterMenuComponent,
    SearchbarComponent,
    UserAvatarComponent,
    UserFormComponent,
    BookingFormComponent,
    BookingFormModalComponent,
    EventViewComponent,
    BookingEditComponent,
    MapControlsComponent,
    PopoutMenuComponent,
    YesNoRadioComponent,
    InfiniteScrollComponent
];

const ENTRY_COMPONENT: Type<any>[] = [
    DateFieldComponent,
    ActionFieldComponent,
    TimeFieldComponent,
    DurationFieldComponent,
    UserSearchFieldComponent,
    UserListFieldComponent,
];

const MATERIAL_MODULES: any[] = [
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    MatRippleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatExpansionModule
];

const CDK_MODULES: any[] = [
    ScrollingModule
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENT,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        APipesModule,
        AInteractiveMapModule,
        ADatePickerModule,
        ACustomEventsModule,
        RouterModule,
        EditorModule,
        ComposerModule,
        ...MATERIAL_MODULES,
        ...CDK_MODULES
    ],
    exports: [
        APipesModule,
        AInteractiveMapModule,
        ACustomEventsModule,
        ADatePickerModule,
        ...COMPONENTS,
        ...ENTRY_COMPONENT,
        ...MATERIAL_MODULES,
        ...CDK_MODULES
    ],
})
export class SharedContentModule {}
