import { Injectable } from '@angular/core';
import { ComposerService } from '@placeos/composer';
import { EngineHttpClient } from '@placeos/ts-client';

import { ServiceManager } from '../service-manager.class';
import { toQueryString } from 'src/app/shared/utilities/api.utilities';
import {
    EventListQueryParams,
    EventShowQueryParams,
    EventDeleteQueryParams,
} from './event.interfaces';
import { CalendarEvent } from './event.class';
import { HashMap } from 'src/app/shared/utilities/types.utilities';
import { User, GuestUser } from '../users/user.class';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private _route: string = '/api/staff/v1/events';

    private get _http(): EngineHttpClient {
        return this._composer.http;
    }

    constructor(private _composer: ComposerService) {
        ServiceManager.setService(CalendarEvent, this);
    }

    /**
     * List events
     * @param q Query parameters to pass to the request
     */
    public list(q: EventListQueryParams = {}): Promise<CalendarEvent[]> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}${query ? '?' + query : ''}`;
            let items = [];
            this._http.get(url).subscribe(
                (list) => (items = list.map((event) => new CalendarEvent(event))),
                (err) => reject(err),
                () => resolve(items)
            );
        });
    }

    /**
     * Get details for calendar event
     * @param id ID of the event
     * @param q Query parameters to pass to the request
     */
    public show(id: string, q: EventShowQueryParams = {}): Promise<CalendarEvent> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}/${encodeURIComponent(id)}${query ? '?' + query : ''}`;
            let item = null;
            this._http.get(url).subscribe(
                (event) => (item = new CalendarEvent(event)),
                (err) => reject(err),
                () => resolve(item)
            );
        });
    }

    /**
     * Add new calendar event
     * @param data Event details
     */
    public add(data: HashMap): Promise<CalendarEvent> {
        return new Promise((resolve, reject) => {
            const url = `${this._route}`;
            let item = null;
            this._http.post(url, data).subscribe(
                (event) => (item = new CalendarEvent(event)),
                (err) => reject(err),
                () => resolve(item)
            );
        });
    }

    /**
     * Update calendar event details
     * @param id ID of the event
     * @param data New Event details
     * @param q Query parameters to pass to the request
     */
    public update(id: string, data: HashMap, q: EventShowQueryParams = {}): Promise<CalendarEvent> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}/${encodeURIComponent(id)}${query ? '?' + query : ''}`;
            let item = null;
            this._http.patch(url, data).subscribe(
                (event) => (item = new CalendarEvent(event)),
                (err) => reject(err),
                () => resolve(item)
            );
        });
    }

    /**
     * Update calendar event details
     * @param id ID of the event
     * @param q Query parameters to pass to the request
     */
    public delete(id: string, q: EventDeleteQueryParams = {}): Promise<void> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}/${encodeURIComponent(id)}${query ? '?' + query : ''}`;
            let item = null;
            this._http.delete(url).subscribe(
                (event) => null,
                (err) => reject(err),
                () => resolve()
            );
        });
    }

    /**
     * List of guests that are attending an event
     * @param id ID of the event
     * @param q Query parameters to pass to the request
     */
    public guests(id: string, q: EventShowQueryParams = {}): Promise<GuestUser[]> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}/${encodeURIComponent(id)}/guests${
                query ? '?' + query : ''
            }`;
            let items = null;
            this._http.get(url).subscribe(
                (list) => (items = list.map((user) => new GuestUser(user))),
                (err) => reject(err),
                () => resolve(items)
            );
        });
    }

    /**
     * List of guests that are attending an event
     * @param id ID of the event
     * @param guest_id Email address of the guest
     * @param q Query parameters to pass to the request
     */
    public checkin(id: string, guest_id: string, state?: boolean): Promise<GuestUser[]> {
        return new Promise((resolve, reject) => {
            const url = `${this._route}/${encodeURIComponent(id)}/guests/${encodeURIComponent(
                guest_id
            )}/checkin${state ? '?state=' + state : ''}`;
            let items = null;
            this._http.get(url).subscribe(
                (list) => (items = list.map((user) => new GuestUser(user))),
                (err) => reject(err),
                () => resolve(items)
            );
        });
    }
}
