<div
    class="date-field"
    role="button"
    form-field
    tabindex="0"
    [class.active]="is_open"
    [attr.disabled]="disabled"
    (keydown.enter)="openDatePicker()"
    [matMenuTriggerFor]="appMenu"
>
    <div class="display">{{ date_string }}</div>
    <div class="icon">
        <app-icon
            [icon]="{
                type: 'icon',
                class: 'material-icons',
                content: is_open ? 'arrow_drop_up' : 'arrow_drop_down'
            }"
        ></app-icon>
    </div>
</div>
<mat-menu #appMenu="matMenu">
    <div mat-menu-item class="date-picker" (click)="$event.stopPropagation()">
        <a-date-picker
            [ngModel]="date"
            [options]="{ from: from, to: until }"
            (ngModelChange)="setValue($event); closeDatePicker()"
        ></a-date-picker>
    </div>
</mat-menu>
