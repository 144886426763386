import { HashMap } from 'src/app/shared/utilities/types.utilities';

/** Building Level data */
export class BuildingLevel {
    /** ID of the building level zone */
    public readonly id: string;
    /** ID of the building zone associated with the level */
    public readonly parent_id: string;
    /** Name of the level */
    public readonly name: string;
    /** Capacity for the level */
    public readonly capacity: number;
    /** URL of the map associated with the level */
    public readonly map_id: string;

    public readonly settings: HashMap = {};

    constructor(_data: HashMap = {}) {
        this.id = _data.id || '';
        this.parent_id = _data.parent_id || '';
        this.name = _data.name || '';
        this.map_id = _data.map_id || '';
        this.capacity = _data.capacity || 0;
    }
}
