<div class="action-field" role="button" form-field tabindex="0" (keydown.enter)="performAction()">
    <div class="display" (tapped)="performAction()">{{ placeholder }}</div>
    <div class="icon" (tapped)="performAction()">
        <app-icon
            [icon]="{
                type: 'icon',
                class: 'material-icons',
                content: show_tooltip ? 'arrow_drop_up' : 'arrow_drop_down'
            }"
        ></app-icon>
    </div>
</div>
