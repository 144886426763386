<label class="wrapper" [attr.for]="idYes" [class.checked]="!!isYes">
    <ng-container *ngIf="!!isYes; then checked; else unchecked"> </ng-container>
    <input [attr.id]="idYes" type="radio" [value]="true" [formControl]="control" />
    Yes
</label>
<label class="wrapper" [attr.for]="idNo" [class.checked]="!isYes">
    <ng-container *ngIf="!control || !control.value; then checked; else unchecked"> </ng-container>
    <input [attr.id]="idNo" type="radio" [value]="false" [formControl]="control" />
    No
</label>

<ng-template #checked>
    <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'check_box' }"></app-icon>
</ng-template>
<ng-template #unchecked>
    <app-icon
        [icon]="{ type: 'icon', class: 'material-icons', content: 'check_box_outline_blank' }"
    ></app-icon>
</ng-template>
