import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';

import { ApplicationService } from 'src/app/services/app.service';
import { BaseDirective } from '../../base.directive';
import { ApplicationIcon } from '../../utilities/settings.interfaces';
import { Router } from '@angular/router';

@Component({
    selector: 'a-topbar-header',
    templateUrl: './topbar-header.component.html',
    styleUrls: ['./topbar-header.component.scss']
})
export class TopbarHeaderComponent extends BaseDirective implements OnInit {
    /** Logo of the application organisation */
    public logo: ApplicationIcon;
    /** Whether to show menu */
    @Input() public menu: boolean;
    /** Emiiter for changes to menu state */
    @Output() public menuChange = new EventEmitter<boolean>();
    /** Whether to reverse the side of the menu icon */
    public reverse: boolean;
    /** Current page title */
    public title: string;
    /** Whether to show searchbar */
    @Input() public search = true;


    constructor(private _service: ApplicationService, private router: Router) {
        super();
    }

    public ngOnInit(): void {
        this._service.initialised.pipe(first(_ => _)).subscribe(() => {
            this.logo = this._service.setting('app.logo_dark') || { type: 'icon' };
            this.reverse = this._service.setting('app.general.menu.position') === 'left';
            this.title = this._service.navTitle;
        });
    }

    /** Set menu state to `true` */
    public showMenu() {
        this.menu = true;
        this.menuChange.emit(this.menu);
    }
}
