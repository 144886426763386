import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { BaseDirective } from '../shared/base.directive';
import { ApplicationService } from '../services/app.service';
import { ApplicationLoadingState, LoadingState } from '../shared/utilities/types.utilities';
import { ApplicationIcon } from '../shared/utilities/settings.interfaces';
import { StaffService } from '../services/data/users/staff.service';

@Component({
    selector: 'app-shell-wrapper',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellWrapperComponent extends BaseDirective implements OnInit {
    /** Loading state of the application */
    public loading_state: ApplicationLoadingState;
    /** Logo of the application organisation */
    public logo: ApplicationIcon;

    constructor(private _service: ApplicationService, private _users: StaffService) {
        super();
    }

    /** Whether the user needs to login */
    public get show_login(): boolean {
        return !this._users.current.is_logged_in;
    }

    /** Whether a module is still loading data */
    public get is_loading(): boolean {
        if (this.loading_state) {
            for (const mod in this.loading_state) {
                if (this.loading_state[mod] && this.loading_state[mod].state === 'loading') {
                    return true;
                }
            }
        }
        return false;
    }

    /** List of modules with a loading state */
    public get loading_state_list(): LoadingState[] {
        const list: LoadingState[] = [];
        for (const mod in this.loading_state) {
            if (this.loading_state[mod]) {
                list.push(this.loading_state[mod]);
            }
        }
        return list;
    }

    public ngOnInit(): void {
        this.subscription(
            'loading',
            this._service.listen<ApplicationLoadingState>('loading', loading => {
                this.loading_state = loading;
            })
        );
        this._service.initialised.pipe(first(_ => _)).subscribe(() => {
            this.logo = this._service.setting('app.logo_dark') || { type: 'icon' };
        });
    }
}
