<div class="wrapper" *ngIf="event">
    <div>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'perm_identity' }"></app-icon>
        <label>{{ organiser.name }}</label>
    </div>

    <div>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'schedule' }"></app-icon>
        <label>{{ when }}</label>
    </div>

    <div *ngIf="spaces && spaces.length">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'place' }"></app-icon>
        <div class="space-list">
            <ng-container *ngFor="let item of spaces">
                <label>{{ item?.name }}</label>
                <a class="link-a " *ngIf="item?.map_id" (touchrelease)="showSpace(item)">
                    View
                </a>
            </ng-container>
        </div>
    </div>
    <div>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'people_outline' }"></app-icon>
        <label>{{ attendees.length }} Attendees</label>
        <a class="link-a " *ngIf="attendees.length>  0" (touchrelease)="showAttendees()">
            View
        </a>
    </div>
    <div *ngIf="!!link">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'screen_share' }"></app-icon>
        <label><a href="{{link}}" target="blank">{{ link }}</a></label>
    </div>
    <div>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'room_service' }"></app-icon>
        <label name="catering">Catering</label>
        <a class="link-a " *ngIf="catering_items" (touchrelease)="showCatering()">
            View
        </a>
    </div>
    <div *ngIf="!!body">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'subject' }"></app-icon>
        <label name="body">{{ body }}</label>
    </div>
</div>
